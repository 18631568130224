import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
import { MdPhone, MdEmail, MdLocationOn } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact"} />
		<Helmet>
			<title>
				DriveCraft Customs & Performance Center
			</title>
			<meta name={"description"} content={"Сучасна станція технічного обслуговування, що спеціалізується на видатному тюнінгу та створенні індивідуальних вихлопних систем для вашого автомобіля."} />
			<meta property={"og:title"} content={"DriveCraft Customs & Performance Center"} />
			<meta property={"og:description"} content={"Сучасна станція технічного обслуговування, що спеціалізується на видатному тюнінгу та створенні індивідуальних вихлопних систем для вашого автомобіля."} />
			<link rel={"shortcut icon"} href={"https://bertapub.net/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://bertapub.net/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://bertapub.net/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://bertapub.net/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://bertapub.net/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://bertapub.net/img/icon.png"} />
			<meta name={"msapplication-TileColor"} content={"https://bertapub.net/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section
			color="--light"
			padding="100px 0"
			sm-padding="40px 0"
			position="relative"
			background="linear-gradient(0deg,rgba(25, 30, 34, 0.8) 0%,rgba(25, 30, 34, 0.8) 100%),--color-darkL2 url(https://bertapub.net/img/pexels-jay-pizzle-4756887.jpg) center/cover"
			quarkly-title="Form-1"
		>
			<Box margin="50px 0px 170px 0px" display="block" flex-wrap="wrap">
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 0px 0px" font="--headline1">
						Контакти
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead">
						Якщо у вас є ще питання, будь ласка, звертайтеся до нас, і ми з радістю надамо вам відповіді та консультацію.{"\n\n"}
					</Text>
				</Box>
				<Box
					padding="16px 16px 16px 16px"
					md-width="100%"
					display="grid"
					grid-template-columns="repeat(3, 1fr)"
					sm-display="block"
				>
					<Box padding="0 0 0 64px" margin="64px 0 0 0" max-width="360px" position="relative">
						<Icon
							left="0"
							category="md"
							icon={MdPhone}
							position="absolute"
							size="48px"
							top="0"
						/>
						<Text font="--base" as="h4" margin="6px 0">
							Телефон
						</Text>
						<Text as="p" margin="6px 0" font="--headline3">
							067 790 85 78{"\n\n"}
						</Text>
					</Box>
					<Box
						position="relative"
						padding="0 0 0 64px"
						sm-padding="64px 0 0 0"
						margin="64px 0 0 0"
						max-width="360px"
					>
						<Icon
							top="0"
							left="0"
							category="md"
							icon={MdEmail}
							position="absolute"
							size="48px"
						/>
						<Text font="--base" as="h4" margin="6px 0">
							Email
						</Text>
						<Text as="p" margin="6px 0" font="--headline3">
							<Link href="mailto:info@bertapub.net" text-decoration="none" hover-text-decoration="underline" color="--light">
								info@bertapub.net
							</Link>
						</Text>
					</Box>
					<Box
						sm-padding="64px 0 0 0"
						margin="32px 0 0 0"
						max-width="360px"
						position="relative"
						padding="0 0 0 64px"
					>
						<Icon
							size="48px"
							top="0"
							left="0"
							category="md"
							icon={MdLocationOn}
							position="absolute"
						/>
						<Text as="h4" margin="6px 0" font="--base">
							Адреса
						</Text>
						<Text as="p" margin="6px 0" font="--headline3">
							вул. Григоровича-Барського, 2А, Київ
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652405d247aa25001fafd00a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});